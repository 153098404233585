import { Box, Grid, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import React from 'react';
import HeroBanner from '../HeroBanner';
import MarkdownContent from '../MarkdownContent';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import Caption from '../shared/Caption';
import Embed from '../shared/Embed';

const MediaComponent = ({ media, isWide }) => {
  if (!media) {
    return null;
  }

  return (
    <Grid xs={12} sm={isWide ? 8 : 6} item order={1}>
      {media?.map((mediaParams, index) => (
        <ImageOrEmbedComponent gutterBottom={index !== media.length - 1} {...mediaParams} />
      ))}
    </Grid>
  )
}

const ImageOrEmbedComponent = ({ image, embed, caption, gutterBottom }) => (
  <Box role="figure" aria-labelledby="caption" marginBottom={gutterBottom ? 3 : 0}>
    {image && (
      <PreviewCompatibleImage
        imageInfo={{
          image: image,
          alt: caption
      }} />
    )}
    {embed && (
      <Embed embed={embed} />
    )}
    {caption && <Caption id="caption">{caption}</Caption>}
  </Box>
)

const ContentComponent = ({ textalign, copy, media }) => {
  return (
    <Box my={{ xs: 4, sm: 8 }}>
      <Grid container spacing={4} direction="row" alignItems="center" justifyContent="center">
        { copy && (
          <Grid xs={12} sm={6} item order={textalign === 'right' ? 2 : 0}>
            <MarkdownContent content={copy} />
          </Grid>
        )}
        <MediaComponent media={media} isWide={!copy} />
      </Grid>
    </Box>
  )
}

const MarkdownPageTemplate = ({ title, content, hero }) => {
  return (
    <section>
      {hero || <HeroBanner title={title}/>}
      <Container>
        { content.map((params) => <ContentComponent {...params} />)}
      </Container>
    </section>
  )
}

MarkdownPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default MarkdownPageTemplate
