import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'
import { MARKDOWN_STYLES } from '../constants/theme'
import { Box } from '@mui/system'

const converter = new showdown.Converter();

const onClick = (e) => {
  if (e.target.tagName.toLowerCase() !== 'a') {
    return
  }

  if (!e.target.hasAttribute("target") && e.target.href.indexOf(window.location.origin) < 0) {
    e.preventDefault();
    e.target.setAttribute("target", "_blank");
    e.target.click();
    return;
  }
}

const MarkdownContent = ({ content, className, styles }) => (
  <Box sx={{ ...MARKDOWN_STYLES, ...styles }}>
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
      onClick={onClick}
    />
  </Box>
)

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

MarkdownContent.defaultProps = {
  styles: {},
}

export default MarkdownContent
