import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import HeroBanner from '../components/HeroBanner'
import Form from '../components/landing-page/Form'
import Layout from '../components/Layout'
import MarkdownPageTemplate from '../components/shared/MarkdownPage'
import { withHelmet } from '../helpers/metadataHelpers'

const HeroComponent = ({
  image,
  title,
  subtitle,
  form,
}) => (
  <HeroBanner image={image} title={title} subtitle={subtitle}>
    <Form labels={form}/>
  </HeroBanner>
)

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { image, title, form, subtitle } = frontmatter

  return (
    <Layout>
      <MarkdownPageTemplate
        content={html}
        hero={<HeroComponent {...{image, title, subtitle, form}} />}
        content={frontmatter.content}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default withHelmet(IndexPage)

export const pageQuery = graphql`
  query LanguagesPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pagename
        title
        subtitle
        description
        form {
          firstName
          lastName
          zipcode
          email
          buttonText
          invalidMessage
          requiredMessage
          errorMessage
        }
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 88) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        content {
          copy
          textalign
          media {
            embed
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 88) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
